
// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import InputFactory from "../../components/inputFactory";
import UserService from "../../services/user";
import {navigate} from "gatsby";
import Button from "../../components/button";
import DuoSection from "../../components/duoSection";
import Seo from '../../components/seo'
import ApiService from "../../services/api";

// Middleware
import CheckoutMiddleware from '../../middleware/checkoutMiddleware'

class Billing extends React.Component {
	state = {
		inputValues: {
			'use_shipping': true,
			address: {}
		},
		inputErrors: {
			address: {}
		},
		isLoading: false
	}

	render() {
		const {inputValues, inputErrors, isLoading} = this.state
		const addressErrors = inputErrors ? {
			given_name: inputErrors.address.given_name,
			family_name: inputErrors.address.family_name,
			street: inputErrors.address.street,
			street_2: inputErrors.address.street_2,
			state: inputErrors.address.state,
			postal_code: inputErrors.address.postal_code,
			city: inputErrors.address.city,
			region: inputErrors.address.region
		} : {}

		return (
			<CheckoutMiddleware>
				<Layout className="nav-blue-half checkout billing">
					<Seo title="Billing" />
					<DuoSection>

						<div>
							<h1 className="generic-template__title">Checkout</h1>
						</div>
						<div>
							<div className="checkout--colour-endeavour">
								<h2>Billing<br/>Information</h2>

								<form onSubmit={this.handleSubmit}>
									<div className="use-shipping">
										<InputFactory
											type="checkbox"
											name="use_shipping"
											label="Use my shipping address"
											value={inputValues['use_shipping']}
											checked={inputValues['use_shipping']}
											onChange={this.handleInputChange}
										/>
									</div>

									{
										inputValues['use_shipping'] ? '' :
											<>
												<InputFactory
													type="input"
													name="given_name"
													scope="address"
													label="First Name*"
													placeholder="First Name*"
													className="text-input"
													value={inputValues?.given_name}
													error={addressErrors?.given_name}
													onChange={this.handleInputChange}
												/>

												<InputFactory
													type="input"
													name="family_name"
													scope="address"
													label="Last Name*"
													placeholder="Last Name*"
													className="text-input"
													value={inputValues['family_name']}
													error={addressErrors?.family_name}
													onChange={this.handleInputChange}
												/>

												<InputFactory
													type="address"
													name="address"
													label="Address*"
													value={inputValues?.address}
													error={addressErrors}
													onChange={this.handleInputChange}/>
											</>
									}

									<Button isLoading={isLoading} type="submit">Next</Button>
								</form>
							</div>
						</div>
					</DuoSection>
				</Layout>
			</CheckoutMiddleware>
		)
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		this.setState({
			isLoading: true
		})

		const {inputValues, inputErrors} = this.state
		const nextPage = '/basket/pay';

		const body = {
			type: 'billing',
		};

		if(!inputValues['use_shipping']) {
			const userService = new UserService();
			const newAddress = await userService.setBillingAddress(inputValues.address)

			if(newAddress.success) {
				const newBillingAddress = newAddress.data.addresses.find((address) => {
					return address.label === 'billing'
				})
				body.address_id = newBillingAddress.id
			} else {
				inputErrors['address'] = newAddress.errors
				this.setState({
					inputErrors: inputErrors,
					isLoading: false
				})
				return
			}
		} else {
			// manual override to use the users' shipping address
			body.clone = 'shipping'
		}

		this.setState({
			isLoading: true
		})

		const apiService = new ApiService();
		const response = await apiService.put('basket/address', {
			body: JSON.stringify(body)
		});

		if(response.success) {
			await navigate(nextPage)
		} else {
			this.setState({
				inputErrors: response.errors,
				isLoading: false
			})
		}

	}
}

export default Billing
